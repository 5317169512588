export default [
  {
    key: 'name',
    label: 'Name',
    sortable: true,
    class: 'text-center align-middle',
    thClass: 'align-middle text-center',
    show: true,
  },
  {
    key: 'rate',
    label: 'Rate',
    sortable: true,
    class: 'text-center align-middle',
    thClass: 'align-middle text-center',
    show: true,
    thStyle: { width: '20%' },
  },
  {
    key: 'action',
    label: 'Action',
    sortable: true,
    class: 'text-center align-middle',
    thClass: 'align-middle text-center',
    thStyle: { width: '20%' },
    show: true,
  },
]
