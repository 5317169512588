
import DynamicRate from '@/models/DynamicRate'
import ViewModel from '@/models/ViewModel'
import {
  Component, Prop, Vue, Ref, Watch,
} from 'vue-property-decorator'

import FormInput from '@/components/FormInput/FormInput.vue'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import CheckboxInput from '@/components/CheckboxInput/index.vue'
import IconAction from '@/components/IconAction/IconAction.vue'
import { clone as _clone } from 'lodash'
import Inventory from '@/models/Inventory'
// @ts-ignore
import LiquorTree from 'liquor-tree'
import Widget from '@/components/Widget/Widget.vue'

import Multiselect from 'vue-multiselect'

import FooterNav from '@/components/FooterNav/FooterNav.vue'
import WebMessage from '@/models/WebMessage'
import dynamic_overwrite_table_fields from './dynamic-overwrite-table'

Vue.use(LiquorTree)

@Component({
  components: {
    FormInput,
    SelectPicker,
    CheckboxInput,
    IconAction,
    Widget,
    Multiselect,
    FooterNav,
  },
})
export default class DynamicRateView extends ViewModel {
  @Ref() readonly treeview: any

  @Ref() readonly treeviewExclude: any

  @Ref() readonly submitBtn: any

  public busy = true

  public dynamic_rate: DynamicRate = new DynamicRate()

  public adunit_filter: string = ''

  public adunit_exclude_filter: string = ''

  public adunits: Array<any> = []

  public selected_adunits: Array<any> = []

  public temp_ad_unit: any = null

  public overwrites: Array<any> = []

  public tree_options = {
    checkbox: true,
    checkOnSelect: false,
    autoCheckChildren: false,
  }

  public get overwrite_table_fields() {
    return dynamic_overwrite_table_fields
  }

  @Watch('dynamic_rate.apply_to_children')
  public onChange(val: any) {
    if (this.treeview) this.applyChildrenToSelect(val)
  }

  public type_options = [
    {
      value: 'cost',
      name: 'Cost',
    },
    {
      value: 'revenue',
      name: 'Revenue',
    },
  ]

  public get title(): string {
    return this.$route.params.id ? 'Edit Dynamic rate' : 'Create Dynamic rate'
  }

  /**
   * Filters selected_adunits for the overwrites selectPicker options
   */
  public get getNotSelected(): Array<any> {
    return this.selected_adunits.filter(sel => {
      if (!this.overwrites.length) return true
      return (
        !this.overwrites.some(custom => custom.id === sel.value)
        && !this.dynamic_rate.exclude_adunit_ids.some(id => id === sel.value)
      )
    })
  }

  /**
   * Removes overwrite by its index
   */
  public removeFromOverwrite(index: number): void {
    this.overwrites.splice(index, 1)
  }

  /**
   * Removes overwrite by its id
   */
  private removeFromOverwritesById(id: string) {
    let index = this.overwrites.findIndex(o => o.id === id)
    if (index >= 0) this.removeFromOverwrite(index)
  }

  /**
   * Get tree full path names
   *
   * If node is a child, it returns tree name to parent name
   */
  public getFullPath(node: any) {
    const fullPath = [node.data.text]
    node.recurseUp((parentEl: any) => fullPath.unshift(parentEl.data.text))
    return fullPath.join(' > ')
  }

  /**
   * Updates dynamicRate exclude_adunit_ids
   *
   * Based on treeviewExclude selected items
   *
   * Also removes the selected options from the overwrites selectPicker
   *
   */
  public updateRemoveSelection() {
    this.dynamic_rate.exclude_adunit_ids = this.treeviewExclude
      .checked()
      .map((x: any) => {
        this.removeFromOverwritesById(x.id)
        return x.id
      })
  }

  /**
   *
   * The computed getNotSelected, filters selected_adunits for the overwrite selectPicker
   */
  public applyChildrenToSelect(action: boolean) {
    let found = this.treeview.checked()
    found.forEach((e: any) => {
      if (action) this.addChild(e)
      else this.removeChild(e)
    })
  }

  /**
   * Find node's child and build options for the overwrite selectPicker
   */
  public addChild(node: any) {
    let childs = this.treeview.findAll(
      (n: any) => n.parent && n.parent.id === node.id,
    )

    childs.forEach((f: any) => {
      this.selected_adunits.push({
        text: f.parent ? this.getFullPath(f) : f.data.text,
        value: f.id,
        parent_id: node.id,
      })
    })
  }

  /**
   * Find node's childs and remove from  overwrite selectPicker options
   *
   * It also removes from the overwrites table
   */
  public removeChild(node: any) {
    this.selected_adunits = this.selected_adunits.filter((ad: any) => {
      if (ad.parent_id !== node.id) {
        return ad
      }
      return false
    })
    this.overwrites = this.overwrites.filter((ov: any) => {
      if (ov.parent_id !== node.id) {
        return ov
      }
      return false
    })
  }

  /**
   * Updates selected_adunits
   * Based on tree node checked status
   */
  public updatedSelection(e: any): void {
    const index = this.selected_adunits.findIndex(ad => ad.value === e.id)
    if (index > -1) {
      this.selected_adunits.splice(index, 1)
      this.removeFromOverwritesById(e.id)
      this.removeChild(e)
    } else {
      if (this.dynamic_rate.apply_to_children) this.addChild(e)

      this.selected_adunits.push({
        text: e.parent ? this.getFullPath(e) : e.data.text,
        value: e.id,
        parent_id: e.parent ? e.parent.id : null,
      })
    }
  }

  /**
   * Used by the selectPicker
   *
   * It creates an object to add in the overwrites array
   */
  public addCustomRate(): void {
    if (!this.temp_ad_unit) return

    const node = this.treeview.find((ad: any) => ad.id === this.temp_ad_unit)[0]

    this.overwrites.unshift({
      name: node.data.text,
      id: this.temp_ad_unit,
      rate: this.dynamic_rate.rate,
      parent_id: node.parent ? node.parent.id : null,
    })
    this.temp_ad_unit = {}
    // @ts-ignore
    this.$refs.selectPicker.local_value = {}
  }

  /**
   * Editing mode only
   *
   * Set checkbox on tree component based on DynamicRate adunit_ids and exclude_adunit_ids
   */
  public async setSelectedCheckBox(): Promise<any> {
    return new Promise(res => {
      setTimeout(() => {
        this.treeview
          .findAll(
            (n: any) =>
              this.dynamic_rate.adunit_ids.includes(n.id) && !n.parent,
          )
          .check()

        if (this.dynamic_rate.apply_to_children) {
          this.treeviewExclude
            .findAll((n: any) =>
              this.dynamic_rate.exclude_adunit_ids.includes(n.id))
            .check()

          this.treeviewExclude.checked().forEach((e: any) => {
            if (e.parent) {
              e.parent.expand()
            }
          })
        }
        res(true)
      }, 1000)
    })
  }

  public init(): void {
    this.busy = true

    let promises = []

    if (this.$route.params.id) {
      promises.push(DynamicRate.find(this.$route.params.id))
    }

    promises.push(Inventory.module.getTree())

    Promise.all(promises).then((responses: any) => {
      if (responses[0] instanceof DynamicRate) {
        this.dynamic_rate = responses[0]
        this.adunits = responses[1]
        this.setSelectedCheckBox().then(() => {
          this.load_overwrites()
        })
      } else {
        this.adunits = responses[0]
      }
      this.busy = false
    })
  }

  /**
   * Editing mode only
   *
   * Populates the overwrites table
   */
  public load_overwrites() {
    this.dynamic_rate.overwrites.forEach((element: any) => {
      for (let idKey in element) {
        const node = this.treeview.find((ad: any) => ad.id === idKey)[0]

        this.overwrites.push({
          name: this.getFullPath(node),
          ...element[idKey],
          id: idKey,
          parent_id: node.parent ? node.parent.id : null,
        })
      }
    })

    this.applyChildrenToSelect(this.dynamic_rate.apply_to_children)
  }

  public checkAdunitQuantity() {
    if (!this.selected_adunits.length) {
      WebMessage.warning('Selecte at least one Ad unit')
      const el: any = this.treeview.$el
      el.scrollIntoView({
        block: 'center',
        behavior: 'smooth',
        inline: 'center',
      })
      return false
    }
    return true
  }

  public save() {
    if (!this.checkAdunitQuantity()) return
    this.busy = false
    this.dynamic_rate.overwrites = this.overwrites.map(ov => ({
      [ov.id]: { rate: ov.rate },
    }))

    this.dynamic_rate.adunit_ids = this.selected_adunits.map(ad => ad.value)

    if (this.dynamic_rate.apply_to_children) {
      this.dynamic_rate.exclude_adunit_ids = this.treeviewExclude
        .checked()
        .map((x: any) => x.id)
    } else {
      this.dynamic_rate.exclude_adunit_ids = []
    }

    this.dynamic_rate
      .save()
      .then(response => {
        this.busy = false
        if (response.status == 200) {
          this.$router.push({ name: 'dynamic_rate' })
        }
      })
      .catch(() => {
        this.busy = false
      })
  }

  public mounted() {
    this.init()
  }
}
